.swal2-modal {
  max-width: 75%;
  border-radius: var(--chakra-radii-2xl);
  padding: 0 0 1rem;
  background-color: #f1f1f1;
}

.swal2-container {
  padding: 0;
  margin: 0;
}

.swal2-actions {
  padding: 0 var(--chakra-space-6);
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0 0;
}

button.swal2-confirm.swal2-styled.swal2-default-outline {
  border-radius: var(--chakra-radii-full);
  font-size: 1rem;
  font-weight: 400;
}

button.swal2-cancel.swal2-styled.swal2-default-outline {
  border-radius: var(--chakra-radii-full);
  font-size: 1rem;
  font-weight: 400;
  color: var(--chakra-colors-black);
}

.swal2-image {
  padding: var(--chakra-space-6);
  margin: 0;
}

#swal2-html-container {
  padding: 0 var(--chakra-space-6);
  margin: auto;
  font-size: 1rem;
  font-weight: 100;
  color: var(--chakra-colors-gray-600);
}
